import barba from "@barba/core";
import Glide from "@glidejs/glide";
import animations from "../animations/animations";

gsap.registerPlugin(ScrollToPlugin);

function playVideo(data) {
    const video = data.next.container.querySelector("video.hero__video");
    if (video) {
        console.log(video);
        video.play();
    }
}

const delay = (n) => {
    n = n || 2000;
    return new Promise((done) => {
        setTimeout(() => {
            done();
        }, n);
    });
};

const leaveAnimation = async () => {
    var tl = gsap.timeline();
    tl.to("#transition", {
        opacity: 1,
        visibility: "visible",
        translateY: "100vh",
        ease: "power1.inOut",
        duration: 1.5,
    });

    tl.to("#transition", {
        opacity: 0,
        visibility: "hidden",
    });

    tl.set("#transition", { translateY: "-170vh" });
    tl.set("#transition", { opacity: 1 });
};

const transition = () => {
    barba.init({
        sync: false,
        debug: true,
        preventRunning: true,
        transitions: [
            {
                once(data) {
                    document.body.classList.remove("invisible");
                    /* Creating a map with a custom marker. */
                    animations(data);
                },

                async beforeOnce(data) {
                    document.body.classList.add("invisible");
                    leaveAnimation();
                    await delay(500);
                    document.body.classList.remove("invisible");
                },

                async leave(data) {
                    leaveAnimation();
                    console.log("leave");
                    await delay(750);
                    gsap.to(window, { scrollTo: 0 }, "-=2");
                },

                enter(data) {
                    animations(data);
                },
            },
        ],
        views: [
            {
                namespace: "contact",
                beforeEnter(data) {
                    const js = data.next.container.querySelectorAll(
                        "script.reload-on-ajax"
                    );

                    [].forEach.call(js, function (script) {
                        var createScript = document.createElement("script");
                        if (typeof script.src !== "undefined" && script.src) {
                            createScript.src = script.src;
                            data.next.container.appendChild(createScript);
                        } else {
                            createScript.innerHTML = script.innerHTML;
                            data.next.container.appendChild(createScript);
                        }
                    });
                },
            },
            {
                namespace: "societe-agences",
                beforeEnter({ next }) {
                    // prevent Google Map API script from being loaded multiple times
                    if (typeof window.createMap === "function") {
                        window.createMap();
                    } else {
                        window.createMap = () => {
                            (function ($) {
                                /**
                                 * initMap
                                 *
                                 * Renders a Google Map onto the selected jQuery element
                                 *
                                 * @date    22/10/19
                                 * @since   5.8.6
                                 *
                                 * @param   jQuery $el The jQuery element.
                                 * @return  object The map instance.
                                 */
                                function initMap() {
                                    var $el = $(".acf-map");

                                    if (typeof google === "undefined") {
                                        setTimeout(() => {
                                            initMap($el);
                                            console.log("instanciate initmap");
                                        }, 500);

                                        return;
                                    }

                                    // Find marker elements within map.
                                    var $markers = $el.find(".marker");

                                    // Create gerenic map.
                                    var mapArgs = {
                                        zoom: 14,
                                        mapTypeId:
                                            google.maps.MapTypeId.ROADMAP,
                                        styles: mapStyles,
                                    };
                                    var map = new google.maps.Map(
                                        $el[0],
                                        mapArgs
                                    );

                                    // Add markers.
                                    map.markers = [];
                                    $markers.each(function () {
                                        initMarker($(this), map);
                                    });

                                    // Center map based on markers.
                                    centerMap(map);

                                    // Return map instance.
                                    return map;
                                }

                                /**
                                 * initMarker
                                 *
                                 * Creates a marker for the given jQuery element and map.
                                 *
                                 * @date    22/10/19
                                 * @since   5.8.6
                                 *
                                 * @param   jQuery $el The jQuery element.
                                 * @param   object The map instance.
                                 * @return  object The marker instance.
                                 */
                                function initMarker($marker, map) {
                                    // Get position from marker.
                                    var lat = $marker.data("lat");
                                    var lng = $marker.data("lng");
                                    var latLng = {
                                        lat: parseFloat(lat),
                                        lng: parseFloat(lng),
                                    };

                                    // Create marker instance.
                                    var marker = new google.maps.Marker({
                                        position: latLng,
                                        map: map,
                                        icon: {
                                            path: "M246.181,0C127.095,0,59.533,102.676,84.72,211.82c17.938,77.722,126.259,280.631,161.462,280.631 c32.892,0,143.243-202.975,161.463-280.631C432.996,103.74,365.965,0,246.181,0z M246.232,224.97 c-34.38,0-62.244-27.863-62.244-62.244c0-34.381,27.864-62.244,62.244-62.244c34.38,0,62.244,27.863,62.244,62.244 C308.476,197.107,280.612,224.97,246.232,224.97z",
                                            fillColor: "#111827",
                                            fillOpacity: 1,
                                            scale: 0.08,
                                            anchor: new google.maps.Point(
                                                250,
                                                500
                                            ),
                                        },
                                    });

                                    // Append to reference for later use.
                                    map.markers.push(marker);

                                    // If marker contains HTML, add it to an infoWindow.
                                    if ($marker.html()) {
                                        // Create info window.
                                        var infowindow =
                                            new google.maps.InfoWindow({
                                                content: $marker.html(),
                                            });

                                        // Show info window when marker is clicked.
                                        google.maps.event.addListener(
                                            marker,
                                            "click",
                                            function () {
                                                infowindow.open(map, marker);
                                            }
                                        );
                                    }
                                }

                                /**
                                 * centerMap
                                 *
                                 * Centers the map showing all markers in view.
                                 *
                                 * @date    22/10/19
                                 * @since   5.8.6
                                 *
                                 * @param   object The map instance.
                                 * @return  void
                                 */
                                function centerMap(map) {
                                    // Create map boundaries from all map markers.
                                    var bounds = new google.maps.LatLngBounds();
                                    map.markers.forEach(function (marker) {
                                        bounds.extend({
                                            lat: marker.position.lat(),
                                            lng: marker.position.lng(),
                                        });
                                    });

                                    // Case: Single marker.
                                    if (map.markers.length == 1) {
                                        map.setCenter(bounds.getCenter());

                                        // Case: Multiple markers.
                                    } else {
                                        map.fitBounds(bounds);
                                    }
                                }

                                var mapStyles = [
                                    {
                                        elementType: "labels.text",
                                        stylers: [
                                            {
                                                visibility: "on",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "landscape.natural",
                                        elementType: "geometry.fill",
                                        stylers: [
                                            {
                                                color: "#f5f5f2",
                                            },
                                            {
                                                visibility: "on",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "administrative",
                                        stylers: [
                                            {
                                                visibility: "on",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "transit",
                                        stylers: [
                                            {
                                                visibility: "on",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "poi.attraction",
                                        stylers: [
                                            {
                                                visibility: "off",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "landscape.man_made",
                                        elementType: "geometry.fill",
                                        stylers: [
                                            {
                                                color: "#ffffff",
                                            },
                                            {
                                                visibility: "on",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "poi.business",
                                        stylers: [
                                            {
                                                visibility: "off",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "poi.medical",
                                        stylers: [
                                            {
                                                visibility: "off",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "poi.place_of_worship",
                                        stylers: [
                                            {
                                                visibility: "off",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "poi.school",
                                        stylers: [
                                            {
                                                visibility: "off",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "poi.sports_complex",
                                        stylers: [
                                            {
                                                visibility: "off",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "road.highway",
                                        elementType: "geometry",
                                        stylers: [
                                            {
                                                color: "#ffffff",
                                            },
                                            {
                                                visibility: "simplified",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "road.arterial",
                                        stylers: [
                                            {
                                                visibility: "simplified",
                                            },
                                            {
                                                color: "#ffffff",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "road.highway",
                                        elementType: "labels.icon",
                                        stylers: [
                                            {
                                                color: "#ffffff",
                                            },
                                            {
                                                visibility: "off",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "road.highway",
                                        elementType: "labels.icon",
                                        stylers: [
                                            {
                                                visibility: "off",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "road.arterial",
                                        stylers: [
                                            {
                                                color: "#ffffff",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "road.local",
                                        stylers: [
                                            {
                                                color: "#ffffff",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "poi.park",
                                        elementType: "labels.icon",
                                        stylers: [
                                            {
                                                visibility: "off",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "poi",
                                        elementType: "labels.icon",
                                        stylers: [
                                            {
                                                visibility: "off",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "water",
                                        stylers: [
                                            {
                                                color: "#71c8d4",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "landscape",
                                        stylers: [
                                            {
                                                color: "#e5e8e7",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "poi.park",
                                        stylers: [
                                            {
                                                color: "#8ba129",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "road",
                                        stylers: [
                                            {
                                                color: "#ffffff",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "poi.sports_complex",
                                        elementType: "geometry",
                                        stylers: [
                                            {
                                                color: "#c7c7c7",
                                            },
                                            {
                                                visibility: "off",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "water",
                                        stylers: [
                                            {
                                                color: "#a0d3d3",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "poi.park",
                                        stylers: [
                                            {
                                                color: "#91b65d",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "poi.park",
                                        stylers: [
                                            {
                                                gamma: 1.51,
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "road.local",
                                        stylers: [
                                            {
                                                visibility: "off",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "road.local",
                                        elementType: "geometry",
                                        stylers: [
                                            {
                                                visibility: "on",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "poi.government",
                                        elementType: "geometry",
                                        stylers: [
                                            {
                                                visibility: "off",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "landscape",
                                        stylers: [
                                            {
                                                visibility: "off",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "road",
                                        elementType: "labels",
                                        stylers: [
                                            {
                                                visibility: "off",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "road.arterial",
                                        elementType: "geometry",
                                        stylers: [
                                            {
                                                visibility: "simplified",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "road.local",
                                        stylers: [
                                            {
                                                visibility: "simplified",
                                            },
                                        ],
                                    },
                                    {
                                        featureType: "road",
                                    },
                                    {
                                        featureType: "road",
                                    },
                                    {},
                                    {
                                        featureType: "road.highway",
                                    },
                                ];

                                $(document).ready(function () {
                                    $(".acf-map").each(function () {
                                        var map = initMap($(this));
                                    });
                                });
                            })(jQuery);
                        };

                        // load the Google Map API script
                        let script = document.createElement("script");
                        script.src =
                            "https://maps.googleapis.com/maps/api/js?key=AIzaSyCU31_Wm_4LySAT1cTp7wuOO6j2wquDKhg&callback=createMap";
                        next.container.appendChild(script);
                    }
                },
            },
            {
                namespace: "societe-qui-sommes-nous",
                beforeEnter(data) {
                    playVideo(data);
                    console.log("play");
                },
            },
            {
                namespace: "front-page",
                beforeEnter(data) {
                    playVideo(data);
                    
                    const slider = data.next.container.querySelector('.glide');
                    if (slider) {
                        let glide = new Glide(slider, {
                            type: 'carousel',
                            autoplay: 2000,
                            perView: 5,
                        })
                
                        glide.mount()
                    }
                },
            },
        ],
    });
};

export default transition;
